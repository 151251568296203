<i18n>
ru:
  comment: Комментарий
ua:
  comment: Коментар
us:
  comment: Comment
</i18n>

<template>
  <arora-textarea
    v-if="loaded"
    data-test-id="order-user-comment"
    v-form-validator="{
      Form: validatorForm,
      Value: comment,
      Required: false,
      Validations: ['length'],
      MaxLength: 200
    }"
    :label="translate('comment.comment')"
    :textarea-rows="2"
    v-model:text="comment"
  />
  <common-skeleton v-else />
</template>

<script setup lang="ts">
import type { HasValidatorForm } from '~types/props'

import { useCommon, vFormValidator } from '@arora/common'

defineProps<HasValidatorForm>()

const { clean, translate } = useI18nSanitized()
const { debounce } = useCommon()
const clientStore = useClientStore()

onMounted(async () => {
  await clientStore.initClientState()
})

const loaded = computed<boolean>(() => clientStore.ClientState?.data !== null)

const commentLocal = ref<string | null>(null)

const comment = computed<string>({
  get: () =>
    commentLocal.value ??
    clientStore.ClientState?.data?.StateOrderData?.Comment ??
    '',
  set: (value) => {
    commentLocal.value = value
    debounce(() =>
      clientStore.updateOrderData({
        comment: clean(value ?? '')
      })
    )
  }
})
</script>
